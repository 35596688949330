<template>
  <div class="content">
    <div class="content__title content__title--party">
      <div class="content__title content__title--party content__title--start">
        <router-link
          :to="{ name: 'primary' }"
          class="button button--light button--no-indent button--small"
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z"
                  fill="#2B93E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z"
                  fill="#2B93E7"/>
          </svg>
        </router-link>
        <h1 class="title title--big title--theme">Редактирование первичной группы</h1>
      </div>
      <button v-if="userRole === 'admin'" @click="openSidebar" class="link" type="button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 17.0134L11.413 16.9984L21.045 7.45839C21.423 7.08039 21.631 6.57839 21.631 6.04439C21.631 5.51039 21.423 5.00839 21.045 4.63039L19.459 3.04439C18.703 2.28839 17.384 2.29239 16.634 3.04139L7 12.5834V17.0134ZM18.045 4.45839L19.634 6.04139L18.037 7.62339L16.451 6.03839L18.045 4.45839ZM9 13.4174L15.03 7.44439L16.616 9.03039L10.587 15.0014L9 15.0064V13.4174Z"
            fill="#2B93E7"/>
          <path
            d="M5 21H19C20.103 21 21 20.103 21 19V10.332L19 12.332V19H8.158C8.132 19 8.105 19.01 8.079 19.01C8.046 19.01 8.013 19.001 7.979 19H5V5H11.847L13.847 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21Z"
            fill="#2B93E7"/>
        </svg>
        <span>Управление</span>
      </button>
    </div>
    <form class="form" @submit.prevent>
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label for="name">Наименование первичной группы членов РСМ<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.name.$error }">
              <textarea
                id="name"
                name="name"
                class="form-control__textarea"
                v-model.trim="form.name"
                placeholder="Введите наименование первичной группы"
              >
              </textarea>
            </div>
            <div class="form-group__error" v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное
              поле
            </div>
          </div>
          <div class="form-group">
            <label for="place">Место создания первичной группы членов РСМ<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.place.$error }">
              <textarea
                id="place"
                name="place"
                class="form-control__textarea"
                v-model.trim="form.place"
                placeholder="Введите место создания первичной группы"
              >
              </textarea>
            </div>
            <div class="form-group__error" v-if="$v.form.place.$dirty && !$v.form.place.required">Обязательное
              поле
            </div>
          </div>
          <div class="form-group">
            <label for="date_start">Дата создания<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.date.$error }">
              <masked-input
                v-model="form.date"
                name="date_start"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date_start"
                autocomplete="off"
                @blur.native="$v.form.date.$touch"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.date.$dirty && !$v.form.date.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label>Протокол о создании<sup v-if="$v.form.date.$dirty && !$v.form.protocol.required">*</sup></label>
            <FakeUploader
              v-if="form.protocol_url"
              @deleteFakePassport="onRemoveFile"
              :photoUrl="form.protocol_url"
              :readonly="userRole !== 'admin' && (form.status === 'review' || form.status === 'accepted')"
            />
            <uploader v-else :getFileHash="getFileHash" limit-files="1" :fileUrl="file_url" @remove-file="onRemoveFile" />
            <div class="form-group__error" v-if="$v.form.date.$dirty && !$v.form.protocol.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label>Адрес нахождения первичной группы<sup>*</sup></label>
            <v-select
              @input="setValues"
              v-model="address_detail"
              :filterable="false"
              :options="address_options"
              @search="onAddressSearch"
              :get-option-label="getLabel"
              placeholder="Введите адрес нахождения первичной группы"
              class="select"
              :class="{'select--error': $v.form.address.$error}"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите адрес нахождения организации</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.address }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.address }}</div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.address.$dirty && !$v.form.address.required">
              Обязательное поле
            </div>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label for="org_vk">Ссылка на страницу ПГ РСМ в ВК</label>
            <div class="form-control">
              <input
                id="org_vk"
                type="url"
                class="form-control__input"
                name="org_vk"
                placeholder="https://example.com"
                v-model.trim="form.org_vk"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="org_instagram">Ссылка на страницу ПГ РСМ в Instagram</label>
            <div class="form-control">
              <input
                id="org_instagram"
                type="url"
                class="form-control__input"
                name="org_instagram"
                placeholder="https://example.com"
                v-model.trim="form.org_instagram"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="org_email">Электронная почта первичной группы<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.org_email.$error }">
              <input
                id="org_email"
                type="email"
                name="org_email"
                class="form-control__input"
                placeholder="Введите электронную почту"
                v-model.trim="form.org_email"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.org_email.$dirty && !$v.form.org_email.email">
              Введите правильный формат почты
            </div>
            <div class="form-group__error" v-if="$v.form.org_email.$dirty && !$v.form.org_email.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label for="head_name">ФИО руководителя<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.head_name.$error }">
              <input
                id="head_name"
                type="text"
                class="form-control__input"
                name="head_name"
                placeholder="Иванов Иван Иванович"
                v-model.trim="form.head_name"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.head_name.$dirty && !$v.form.head_name.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label>Контактный телефон руководителя (по желанию)</label>
            <vue-tel-input
              v-model="phone_value"
              defaultCountry="RU"
              mode="international"
              type="tel"
              class="phonepicker"
              :class="{ 'phonepicker--error': !is_phone_valid }"
              @validate="onValidatePhone"
              :inputOptions="phone_picker_options"
              validCharactersOnly
            >
              <template slot="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
                  <path
                    d="M4.96 6.75L.23 1.47a.93.93 0 010-1.22.71.71 0 011.09 0L5.5 4.92 9.68.25a.71.71 0 011.1 0c.3.34.3.88 0 1.22L6.03 6.75A.73.73 0 015.5 7c-.2 0-.4-.08-.54-.25z"
                    fill="#2B93E7"></path>
                </svg>
              </template>
            </vue-tel-input>
            <div class="form-group__error" v-if="!is_phone_valid">Недопустимое значение для номера телефона</div>
          </div>
          <div class="form-group">
            <label for="head_email">Электронная почта руководителя (по желанию)</label>
            <div class="form-control">
              <input
                id="head_email"
                type="email"
                name="head_email"
                class="form-control__input"
                placeholder="Введите электронную почту"
                v-model.trim="form.head_email"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="count">Количество членов РСМ<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.count.$error }">
              <input
                id="count"
                type="number"
                min="0"
                name="count"
                class="form-control__input"
                placeholder="Введите электронную почту"
                v-model.trim="form.count"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.count.$dirty && !$v.form.count.required">
              Обязательное поле
            </div>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button
          v-if="userRole !== 'admin' && form.status === 'draft'"
          @click="onCheckForm('draft')"
          class="button button--gray"
          type="button"
          :class="{ 'preload': loading }"
          :disabled="$v.form.$error"
        >
          <span>Сохранить черновик</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
        <button
          v-if="userRole !== 'admin' && (form.status === 'draft' || form.status === 'rejected')"
          @click="onCheckForm('review')"
          class="button"
          type="button"
          :class="{ 'preload': loading }"
          :disabled="$v.form.$error"
        >
          <span>Отправить на проверку</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
        <button
          v-if="userRole === 'admin'"
          @click="onCheckForm(form.status)"
          class="button"
          type="submit"
          :class="{ 'preload': loading }"
          :disabled="$v.form.$error"
        >
          <span>Сохранить изменения</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
    <SidebarManage
      :title="'Управление'"
      :class="{'sidebar-manage--full': show_sidebar}"
      @close-sidebar="show_sidebar = !show_sidebar"
    >
      <form>
        <div class="form-group">
          <label>Статус</label>
          <v-select
            @input="sendManage"
            v-model="manage.status"
            :reduce="status => status.id"
            :searchable="false"
            :clearable="false"
            :options="status_options"
            :get-option-label="getLabel"
            placeholder="Выберите статус"
            class="select"
          >
            <template slot="open-indicator">
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template slot="option" slot-scope="option">
              <div class="select__item d-center">
                {{ option.name }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.name }}
              </div>
            </template>
          </v-select>
        </div>
        <div class="form-group">
          <label for="comment_tech">Служебный комментарий</label>
          <div class="form-control">
            <textarea
              @change="sendManage"
              id="comment_tech"
              name="comment_tech"
              class="form-control__textarea"
              v-model.trim="manage.comment_tech"
              placeholder="Видят только администраторы"
            >
            </textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="comment_cancel">Комментарий отказа</label>
          <div class="form-control">
            <textarea
              @change="sendManage"
              id="comment_cancel"
              name="comment_cancel"
              class="form-control__textarea"
              v-model.trim="manage.comment_cancel"
              placeholder="Видит РО на анкете со статусом 'Отклонена'"
            >
            </textarea>
          </div>
        </div>
      </form>
      <div class="sidebar-card">
        <div class="sidebar-card__item sidebar-card__item--between">
          <b>Дата создания</b> <span>{{ form.created_at }}</span>
        </div>
        <div class="sidebar-card__item sidebar-card__item--between">
          <b>Кем создано</b> <span>{{ form.created_name }}</span>
        </div>
        <div class="sidebar-card__item sidebar-card__item--between">
          <b>Дата обновления</b> <span>{{ form.updated_at }}</span>
        </div>
        <div class="sidebar-card__item sidebar-card__item--between">
          <b>Кем обновлено</b> <span>{{ form.updated_name || '-' }}</span>
        </div>
      </div>
    </SidebarManage>
  </div>
</template>

<script>
import { isAfter } from 'date-fns'
import {debounce} from 'lodash'
import {validationMixin} from 'vuelidate'
import {required, email, requiredIf} from 'vuelidate/lib/validators'
import MaskedInput from 'vue-masked-input'
import Uploader from '@/components/Uploader'
import {VueTelInput} from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import SidebarManage from "@/components/SidebarManage"
import FakeUploader from '@/components/FakeUploader'

export default {
  name: 'PrimaryCreate',
  mixins: [validationMixin],
  components: {
    MaskedInput,
    Uploader,
    VueTelInput,
    SidebarManage,
    FakeUploader,
  },
  validations: {
    form: {
      name: {required},
      place: {required},
      date: {required},
      protocol: {
        required: requiredIf(function () {
          return isAfter(new Date(this.adobeDate(this.form.date)), new Date('06.30.2021'))
        })
      },
      address: {required},
      org_email: {required, email},
      head_name: {required},
      count: {required},
      status: {
        required: requiredIf(function(){
          return this.userRole === 'admin'
        })
      },
    },
  },
  data() {
    return {
      form: {
        protocol: '',
        address: ''
      },
      manage: {},
      file_url: `${process.env.VUE_APP_API_URL}files/`,
      address_detail: null,
      address_options: [],
      status_options: [],
      loading: false,
      phone_value: '',
      is_phone_valid: true,
      phone_picker_options: {
        placeholder: 'Введите номер телефона'
      },
      show_sidebar: false,
    }
  },
  computed: {
    userRegion() {
      return this.$store.state.organization
    },
    userRole() {
      return this.$store.state.userRole
    }
  },
  mounted() {
    if (this.userRegion) this.form.region_org = this.userRegion
    if (this.userRole === 'admin') {
      this.$store.dispatch('status/GET_LIST')
        .then(response => {this.status_options = response.data.results})
    }
    this.fetchForm()
  },
  methods: {
    adobeDate(date) {
      return `${date.slice(3,5)}.${date.slice(0,2)}.${date.slice(6)}`
    },
    fetchForm () {
      this.$store.dispatch('primary/GET_DATA', this.$route.params.id)
        .then(response => {
          this.form = {...this.form, ...response.data}
          this.org_detail = response.data.entity_json
          let { status, comment_tech, comment_cancel } = response.data
          this.manage = { status, comment_tech, comment_cancel }
          this.phone_value = response.data.head_phone
          this.address_detail = response.data.address_json
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка получения данных'
          })
        })
    },
    onCheckForm(saveType = '') {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        if (saveType) this.form.status = saveType
        this.sendForm()
      }
    },
    sendForm() {
      this.loading = true
      this.$store.dispatch('primary/PATCH_DATA', this.form)
        .then(() => {
          this.loading = false
          this.$router.push({name: 'primary'})
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Изменения сохранены.'
          });
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка сохранения изменений.'
          });
        });
    },
    sendManage() {
      this.form = {...this.form, ...this.manage}
      this.$store.dispatch('primary/PATCH_DATA', {id: this.$route.params.id, data: this.manage})
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Изменения сохранены.'
          });
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка сохранения данных.'
          });
        });
    },
    onAddressSearch (search, loading) {
      loading(true);
      this.searchAddress(loading, search, this);
    },
    searchAddress: debounce((loading, search, vm) => {
      vm.$store.dispatch('FIND_ADDRESS', { search }).then(response => {
        vm.address_options = response.data;
        loading(false);
      });
    }, 350),
    setValues() {
      if (this.address_detail) {
        this.form.address_json = this.address_detail
        this.form.address = this.address_detail.address
      } else {
        this.form.address_json = ''
        this.form.address = ''
      }
      this.$v.form.address.$touch()
    },
    getLabel(option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    onValidatePhone(number) {
      this.is_phone_valid = number.valid
      if (number.valid && number.number !== '') {
        this.form.head_phone = number.number
      } else {
        this.form.head_phone = ''
      }
      if (!number.hasOwnProperty('number')) {
        this.is_phone_valid = true
      }
    },
    getFileHash(value) {
      this.form.protocol = value
      this.$v.form.protocol.$touch()
    },
    onRemoveFile() {
      this.form.protocol = ''
      this.form.protocol_url = ''
      this.$v.form.protocol.$touch()
    },
    openSidebar() {
      this.show_sidebar = true
    }
  }
}
</script>
